@font-face {
	font-family: "GreycliffCF-Bold";
	src: url("../fonts/greycliff-cf/GreycliffCF-Bold.eot"); /* IE9 Compat Modes */
	src: url("../fonts/greycliff-cf/GreycliffCF-Bold.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/greycliff-cf/GreycliffCF-Bold.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Bold.woff") format("woff"),
		/* Pretty Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Bold.ttf") format("truetype"),
		/* Safari, Android, iOS */
			url("../fonts/greycliff-cf/GreycliffCF-Bold.svg#GreycliffCFBold")
			format("svg"); /* Legacy iOS */
}

@font-face {
	font-family: "GreycliffCF-DemiBold";
	src: url("../fonts/greycliff-cf/GreycliffCF-DemiBold.eot"); /* IE9 Compat Modes */
	src: url("../fonts/greycliff-cf/GreycliffCF-DemiBold.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/greycliff-cf/GreycliffCF-DemiBold.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-DemiBold.woff")
			format("woff"),
		/* Pretty Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-DemiBold.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../fonts/greycliff-cf/GreycliffCF-DemiBold.svg#GreycliffCFDemiBold")
			format("svg"); /* Legacy iOS */
}

// @font-face {
//   font-family: "GreycliffCF-ExtraBold";
//   src: url("../fonts/greycliff-cf/GreycliffCF-ExtraBold.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-Heavy";
//   src: url("../fonts/greycliff-cf/GreycliffCF-Heavy.otf");
// }

@font-face {
	font-family: "GreycliffCF-Light";
	src: url("../fonts/greycliff-cf/GreycliffCF-Light.eot"); /* IE9 Compat Modes */
	src: url("../fonts/greycliff-cf/GreycliffCF-Light.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/greycliff-cf/GreycliffCF-Light.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Light.woff") format("woff"),
		/* Pretty Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Light.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../fonts/greycliff-cf/GreycliffCF-Light.svg#GreycliffCFLight")
			format("svg"); /* Legacy iOS */
}

// @font-face {
//   font-family: "GreycliffCF-Medium";
//   src: url("../fonts/greycliff-cf/GreycliffCF-Medium.otf");
// }

@font-face {
  font-family: "GreycliffCF-Regular";
  src: url("../fonts/greycliff-cf/GreycliffCF-Regular.otf");
  src: url("../fonts/greycliff-cf/GreycliffCF-Regular.eot"); /* IE9 Compat Modes */
	src: url("../fonts/greycliff-cf/GreycliffCF-Regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/greycliff-cf/GreycliffCF-Regular.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Regular.woff") format("woff"),
		/* Pretty Modern Browsers */
			url("../fonts/greycliff-cf/GreycliffCF-Regular.ttf")
			format("truetype"),
		/* Safari, Android, iOS */
			url("../fonts/greycliff-cf/GreycliffCF-Regular.svg#GreycliffCFRegular")
			format("svg"); /* Legacy iOS */
}

// @font-face {
//   font-family: "GreycliffCF-BoldOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-BoldOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-DemiBoldOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-DemiBoldOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-ExtraBoldOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-ExtraBoldOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-HeavyOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-HeavyOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-LightOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-LightOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-MediumOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-MediumOblique.otf");
// }

// @font-face {
//   font-family: "GreycliffCF-RegularOblique";
//   src: url("../fonts/greycliff-cf/GreycliffCF-RegularOblique.otf");
// }

h1,h2,h3,h4,h5,h6,p {
	white-space: pre-line;
}
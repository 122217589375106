@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/responsive";

.ResultPage {
	&__success,
	&__fail {
		margin: 65px auto;
		display: flex;
		flex-direction: column;
		justify-self: center;
	}

	&__success-icon {
		align-self: center;
		width: 96px;
		height: 96px;
		color: $color-green;
	}

	&__fail-icon {
		align-self: center;
		width: 96px;
		height: 96px;
		color: $color-red;
	}

	&__title {
		text-align: center;
		color: $color-navy;
		font-size: 28px;
		margin-top: 28px;
	}

	&__description {
		text-align: center;
		font-family: "GreycliffCF-Light", sans-serif;
		font-size: 0.875rem;

		p {
			margin: 0;
		}
	}

	&__text {
		text-align: center;
		font-family: "GreycliffCF-light", sans-serif;
		color: #000000;

		&--blue {
			color: $color-blue;
		}
	}
}

.info {
	display: inline-block;
	position: relative;
	padding: 25px 50px 25px 25px;
	border: 1px solid $color-light-grey;
	align-self: center;
	margin: 18px auto;

	@include screen-size("small") {
		padding: 25px 100px 25px 25px;
	}

	&__label {
		font-family: "GreycliffCF-light", sans-serif;
		color: #000000;
	}

	&__value {
		font-family: "GreycliffCF-bold", sans-serif;
		color: #000000;
	}

	&__copy-button {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		border-radius: 40px;

		border: none;
		overflow: hidden;
		background-color: transparent;
		cursor: pointer;
		transition: color 0.2s linear;
		color: $color-grey;

		@include screen-size("small") {
			width: 50px;
			height: 50px;
		}

		&:hover,
		&:focus {
			color: $color-yellow;
		}
	}

	&__copy-icon {
		width: 25px;
		height: 25px;

		@include screen-size("small") {
			width: 30px;
			height: 30px;
		}
	}
}

@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Card {
	position: relative;
	box-sizing: border-box;

	overflow-x: hidden;

	margin-left: 30px;
	margin-right: 30px;

	border-radius: 10px;

	padding-top: 29px;
	padding-bottom: 29px;

	background-color: #ffffff;

	-webkit-box-shadow: 5px 4px 10px 0px $color-shadow;
	-moz-box-shadow: 5px 4px 10px 0px $color-shadow;
	//box-shadow: 5px 4px 10px 0px $color-shadow;
	box-shadow: 0px 0px 20px 2px #979797;
	flex: 1;

	@include screen-size("small") {
		margin-left: 60px;
		margin-right: 60px;
	}
}

.link {
	text-decoration: none;
	transition: color 0.2s linear;
	color: #ffffff;

	&:link {
		color: #ffffff;
	}
	&:visited {
		color: #ffffff;
	}
	&:hover {
		color: $color-yellow;
	}
	&:active {
		color: #ffffff;
	}

	&--navy {
		&:link {
			color: $color-navy;
		}
		&:visited {
			color: $color-navy;
		}
		&:hover {
			color: $color-yellow;
		}
		&:active {
			color: $color-navy;
		}
	}

  &--blue {
		&:link {
			color: $color-blue;
		}
		&:visited {
			color: $color-blue;
		}
		&:hover {
			color: $color-navy;
		}
		&:active {
			color: $color-blue;
		}
	}

  &--underline {
    text-decoration: underline;
  }
}

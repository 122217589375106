@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Select {
	cursor: pointer;
	display: block;
	height: 32px;
	padding: 2px 28px 2px 2px;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid $color-light-grey;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #ffffff;
	height: 46px;
	padding: 0px 43px 0px 9px;
	// Caret Down Icon
	background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23979797%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right 6px top 50%;
	/* icon size, then gradient */
	background-size: 1rem auto;

	@include screen-size("small") {
		height: 42px;
		padding: 8px 34px 8px 8px;
		background-position: right 11px top 50%;
	}

	&::-ms-expand {
		display: none;
	}

	&:focus {
		// border-color: #aaa;
		/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
		// box-shadow: 0 0 1px 1px $color-blue;
		// box-shadow: 0 0 0 0 -moz-mac-focusring;
		// color: #222;
		background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%232a51a3%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
		outline: $color-blue auto 1px;
	}

	option {
		font-weight: normal;
	}

	&:disabled,
	&[aria-disabled="true"] {
		color: $color-light-grey;
		background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
			linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
	}

	&:disabled:hover,
	&[aria-disabled="true"] {
		border-color: #aaa;
	}
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .Select,
:root:lang(ar) .Select,
:root:lang(iw) .Select {
	background-position: left 0.7em top 50%, 0 0;
	padding: 0.6em 0.8em 0.5em 1.4em;
}

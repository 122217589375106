@import "../../../../assets/styles/variables";

.Timeline {
  position: relative;

  @media mobil {
    flex-direction: column;
    padding: 0 5px;
    margin-bottom: 24px;
  }

  &__line {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    border: solid 3px;
    background-color: #fff;
    border-radius: 50%;
    margin: 0 auto 22px;
    z-index: 1;
    position: relative;
    border-color: #ffc900;

    @media only screen and (max-width: 650px) {
      margin: 38px auto 0px;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 34px;

    @media small {
      display: flex;
      justify-content: flex-start;
      min-height: 130px;
      max-width: unset;
      text-align: left;
    }

    @media only screen and (max-width: 650px) {
      flex-direction: column;
      padding: 0 5px;
      margin-bottom: 24px;
    }
  }
  &__item {
    text-align: center;
    // max-width: 234px;
    flex-grow: 1;
    flex-basis: auto;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 650px) {
      display: flex;
      justify-content: flex-start;
      min-height: 130px;
      max-width: fit-content;
      text-align: left;
      &:last-child ::after {
        width: 0px;
        background: #fff;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 3px;
      //      background: #666;
      background: #ffc900;

      display: block;
      position: absolute;
      top: 105px;
      left: 50%;
      z-index: 0;

      @media only screen and (max-width: 650px) {
        position: relative;
        display: block;
        border-top: 104px solid #ffc900;
        width: 3px;
        margin: 13px 0px 0px -13px;
        top: 48px;
        left: -75%;
      }
    }
    &:last-child:last-child::after {
      width: 0%;
    }
  }
  &__item-icon {
    width: 58px;
    height: 58px;
  }
  &__item-text {
    font-family: "GreycliffCF-DemiBold";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    // margin-top: 20px;
    font-style: normal;
    color: #000000;
    @media only screen and (max-width: 650px) {
      text-align: left;
      padding-left: 12px;
    }
  }
  &__item-subText {
    font-family: "GreycliffCF-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    // width:250px;
    color: #4f4f4f;
    // margin-left:50px;
    // margin-top: 20px;

    @media only screen and (max-width: 650px) {
      max-width: 295px;
      text-align: left;
    }
  }
}

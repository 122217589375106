@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Modal {
	position: fixed;
	z-index: 900;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	margin-left: 0;
	margin-right: 0;

	background-color: $color-overlay;

	display: flex;
	justify-content: center;
	align-items: center;

	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		justify-content: center;
		align-items: center;
		padding: 15px;
		width: 100%;
		max-width: 600px;
		max-height: 100%;

		@include screen-size("small") {
			padding: 40px;
		}
	}

	&__card {
		max-height: 80vh;
		overflow: auto;
		margin-left: 0;
		margin-right: 0;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-left: 10px;
		padding-right: 10px;

		@include screen-size("small") {
			padding-left: 40px;
			padding-right: 40px;
		}
	}

	&__title {
		margin-top: 12px;
		margin-bottom: 16px;
		color: $color-navy;
		text-transform: uppercase;
		font-size: 1rem;
		font-family: "GreycliffCF-Bold", sans-serif;
		font-weight: bold;
		text-align: center;
		max-width: 100%;

		@include screen-size("small") {
			font-size: 1.75rem;
		}
	}

	&__description {
		text-align: center;
	}

	&__error-icon {
		width: 55px;
		height: 55px;
		color: $color-red;

		@include screen-size("small") {
			width: 90px;
			height: 90px;
		}
	}

	&__success-icon {
		width: 55px;
		height: 55px;
		color: $color-red;
	}

	&__button {
		margin-top: 24px;
		padding: 13px;
		min-width: 169px;
	}
}

.ProcessBar {
  position: relative;
  padding-top: 21px;

  max-width: 100%;
  width: 100%;
  min-width: 0;

  &__line {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    border: solid 3px;
    border-radius: 50%;
    margin: 0 auto 22px;
    z-index: 1;
    position: relative;
    background: #ffffff;
    border: 2px solid #8e9fad;
  }

  &__lineReady {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    border: solid 3px;
    border-radius: 50%;
    margin: 0 auto 22px;
    z-index: 1;
    position: relative;
    background: #ffffff;
    border: 3px solid #2a51a3;
  }

  &__fillOk {
    // background: #2a51a3;
    background-image: url("../../../assets/images/Check_fill.png");
    background-position: 65% 75%;
    border: 3px solid #2a51a3;
  }
  &__items {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 15px;

    @media small {
      display: flex;
      justify-content: flex-start;
      min-height: 130px;
      max-width: unset;
      text-align: left;
    }
  }
  &__item {
    text-align: center;
    flex-grow: 1;
    flex-basis: auto;
    position: relative;
    width: 100%;

    &::after {
      content: "";
      height: 3px;
      background: #8e9fad;
      //     border: 1px solid #8E9FAD;

      display: block;
      position: absolute;
      top: 10px;
      left: 50%;
      z-index: 0;

      width: 100%;
      // @media mobil {
      //   width: 300%;
      // }
    }
    &:last-child:last-child::after {
      width: 0%;
    }

    // @media mobil {
    //   display: flex;
    //   justify-content: flex-start;
    //   min-height: 130px;
    //   max-width: unset;
    //   text-align: left;
    // }
  }

  &__itemOk {
    &::after {
      background: #2a51a3;
    }
  }

  &__item-icon {
    width: 58px;
    height: 58px;
  }
  &__item-text {
    font-family: "GreycliffCF-DemiBold";
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: center;
    margin-top: -16px;
    font-style: normal;
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    font-weight: 400;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #0b519d;
  }
  &__item-subText {
    font-family: "GreycliffCF-light";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #4f4f4f;
    margin-top: 20px;
  }
}

@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Footer {
	box-sizing: border-box;
	//display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	color: #ffffff;
	padding: 1rem 1rem;
	margin-top: 7rem;
	background: linear-gradient(90deg, #034DA2 23.56%, #C4A82F 129.3%);
	width: 100%;

	@include screen-size("small") {
		flex-direction: row;
		
	}

	&__link {
		text-align: center;
	}

	&__link-separator {
		display: none;

		@include screen-size("small") {
			display: block;
		}
	}
}

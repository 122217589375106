.PersonalInfo {
  &__grid {
    margin: 44px 0 84px;
  }

  &__buttons {
    margin-top: 76px;
    display: flex;
    justify-content: center;
  }
  &__card {
    border: 0.3px solid #c4c4c4;

    background: linear-gradient(0deg, #f5f7f9, #f5f7f9);

    margin-top: 20px;

    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    height: 100%;
  }
  &__cardHeader {
    font-family: "GreycliffCF-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #253342;
    border-bottom: 0.3px solid #979797;
    padding-bottom: 5px;
    padding-left: 10px;
  }

  &__cardLabel {
    padding-bottom: 5px;
    padding-left: 10px;
  }
  &__cardLabelLeft {
    text-align: end;

    color: #253342;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__textFamily{
    font-family: 'GreycliffCF-Light';
  }
}

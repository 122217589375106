.heading {
	font-family: "GreycliffCF-Bold", sans-serif;
	font-size: 28px;

	&--navy {
		color: $color-navy;
	}

	&--center {
		text-align: center;
	}

	&--page-title {
		margin-top: 84px;
	}
}

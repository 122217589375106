.form {
	&__input-container {
		// flex: 6;
		margin-bottom: 10px;
		position: relative;

		&__flex {
			display: flex;
		}
	}

	&__label {
		// flex: 4;
		margin-top: 6px;
		padding-right: 4px;

		@include screen-size("small") {
			margin-top: 10px;
		}
	}

	&__group {
		padding-top: 1rem;
		margin-top: 6px;
		padding-right: 4px;
	}

	&__input {
		box-sizing: border-box;
		flex: 1;
		border: 1px solid $color-light-grey;
		height: 56px;
		width: 100%;
		padding: 5px;

		&:focus {
			outline: $color-blue auto 1px;
		}

		@include screen-size("small") {
			padding: 8px;
			height: 42px;
		}

		&--has-tooltip {
			padding-right: 40px;

			@include screen-size("small") {
				padding-right: 40px;
			}
		}
	}

	&__validation-error {
		margin-top: 2px;
		margin-bottom: 6px;
		font-size: 0.625rem;
		color: #ff0000;
	}

	&__info-tooltip {
		position: absolute;
		display: inline-block;
		right: 6px;
		top: 5px;
		// top: 50%;
		// transform: translateY(-50%);

		@include screen-size("small") {
			top: 7px;
		}
	}
}

textarea.form__input {
	height: auto;
	resize: none;
}

@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";
@import "../../../assets/styles/animations/spin";

.SpinnerOverlay {
	position: fixed;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	background-color: $color-overlay;

	&__icon {
		color: $color-yellow;
		width: 80px;
		height: 80px;
		animation-name: spin;
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 22px;
  padding-bottom: 22px;
  margin-bottom: 30px;
  background: linear-gradient(90.34deg, #034da2 22.87%, #ffc40c 96.16%, rgba(3, 77, 162, 0) 120.59%);

  @include screen-size("large") {
    padding-top: 22px;
    // padding-bottom: 97px;
    background-image: url("../../../assets/images/Ellipse_16 (1).png");
    background-repeat: round;
    // margin-bottom: 120px;
    // border-radius: 0% 0% 85% 85%;
    // padding-bottom: 21px;
  }

  &__line {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;

    padding-bottom: 3px;
    // margin-bottom: 30px;
    background: linear-gradient(90deg, #034da2 23.56%, #c4a82f 129.3%);
  }
  &__logo {
    width: 36px;
    height: auto;
    align-self: center;

    // @include screen-size("small") {
    //   width: 60px;
    // }
  }
  &__left-arrow {
    width: 14px;
    height: 14px;
    vertical-align: text-bottom;
  }

  &__icon-image {
    display: inline-block;
    width: 12px;
    margin-right: 3px;
    height: auto;
  }

  &__back-to-kktcell {
    position: absolute;
    left: 15px;
    top: 36px;
    color: #ffffff;

    @include screen-size("small") {
      left: 72px;
      top: 42px;
    }
  }
  &__mainHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 54px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #ffffff;

    @include screen-size("mobil") {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
    }
  }

  &__subHeading {
    font-family: "GreycliffCF-light";

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    letter-spacing: 0.02em;

    color: #ffffff;

    @include screen-size("mobil") {
      font-family: "GreycliffCF-light";
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

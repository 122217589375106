.ChooseNumber {
  &__radio-group {
    display: table;
    width: 100%;
    border-spacing: 1rem;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  &__buttons {
    margin: 44px 0 84px;
    display: flex;
    justify-content: center;
  }

  &__button {
    margin: 5px;
  }

  &__text {
    text-align: center;
    max-width: 350px;
    margin: 48px auto 0;
  }

  &__description {
    font-family: "GreycliffCF-Light";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;

    color: #253342;
  }
}

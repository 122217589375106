@import "../../../assets/styles/responsive";

.CountdownTimer {
  position: absolute;
  display: inline-block;
  top: 145px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  @include screen-size("large") {
    top: 145px;
  }

  &__text {
    font-family: "GreycliffCF-Light", sans-serif;
    color: #979797;
    font-size: 14px;
    margin-bottom: 4px;
  }

  &__timer {
    font-family: "GreycliffCF-DemiBold", sans-serif;
    color: #000000;
    display: inline-block;
    background-color: #dee3ed;
    padding: 4px 16px;
    border-radius: 50px;
    font-size: 14px;
    margin: 0 auto;
    width: 64px;
  }

  &__selocan {
    width: 93px;
    height: auto;
  }
}

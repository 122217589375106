@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Landing {
  &__image {
    width: 100%;
    max-width: 500px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    display: flex;

    @include screen-size("small") {
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      margin-top: 70px;
    }
  }

  &__heading {
    margin-top: 70px;
  }

  &__text {
    font-size: 18px;
    max-width: 420px;
    margin-top: 56px;
    margin-left: auto;
    margin-right: auto;
  }

  &__timeline {
    margin-top: 70px;
  }

  &__buttons {
    margin: 65px 0;
    display: flex;
    justify-content: center;
  }

  &__button {
    padding: 20px;
    padding-right: 57px;
    padding-left: 57px;
    border-radius: 20px;
    background-color: #ffffff;
    margin: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    // display: flex;
    // align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2a51a3;
    border: none;
    font-family: "GreycliffCF-Bold";
    cursor: pointer;
  }

  &__card {
    background: linear-gradient(180deg, #034da2 0%, #c5a92f 108.49%, #dbb322 121.59%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    /* 	padding-top: 10px;
    padding-bottom: 40px;
	padding-left: 80px;
	padding-right: 80px; */
    display: flex;
    flex-flow: column;

    &:hover {
      -webkit-box-shadow: 0px 0px 18px 0px rgb(0 0 0);
      -moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 18px 0px rgb(0 0 0);
    }
    // @include screen-size("mobil") {
    //   padding-bottom: 20px;
    // }
  }

  &__cardHeader {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    display: block;
    color: #ffffff;
    font-family: "GreycliffCF-Bold";
    padding-top: 25px;
  }
  &__cardText {
    padding: 37px 0px;
    font-family: "GreycliffCF-light";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;

    color: #ffffff;
    max-height: 1px;

    @include screen-size("mobil") {
      padding: 37px 10px;
      font-family: "GreycliffCF-light";
      font-weight: 400;
      font-size: 16px;
      line-height: 17px;
      max-height: 1px;
      padding-left: 41px;
      padding-right: 41px;
    }
  }

  &__sbuttons {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    background: #d9d9d9;
    border-radius: 3px;
  }

  &__sbutton {
    background: #d9d9d9;
    border-radius: 3px;
    border: none;
    height: 105px;
    font-family: GreycliffCF-light;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
    letter-spacing: 0.02em;

    color: #4f4f4f;
  }
  &__ssdiv {
    display: flex;
  }

  &__stepperBorder {
    border: 3px solid #ffc900;

    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background: #ffffff;
      border: 3px solid #ffc900;
      overflow: hidden;
      border-radius: 9px;
      top: -3px;
    }
  }
}

@import "../../../assets/styles/variables";

.RadioButton {
	cursor: pointer;
	display: table-row;

	& > div {
		display: table-cell;
		vertical-align: middle;
	}

  &--checks-on-top > div:first-child {
    vertical-align: top;
    padding-top: 6px;
  }

	&__cell {
		display: table-cell;
		vertical-align: middle;
	}

	&__select-cell {
		padding-right: 10px;
	}

	&__select {
		border-radius: 20px;
		box-sizing: border-box;
		width: 18px;
		height: 18px;
		border: 1px solid #828282;

		&--selected {
			background-color: $color-blue;
			border-color: $color-blue;
			text-align: center;
		}
	}

	&__children {
		display: flex;
		margin-left: 10px;
	}

	&__checked {
		width: 12px;
		height: 12px;
		font-size: 12px;
		fill: #ffffff;
	}
}

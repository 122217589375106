body {
	font-size: $font-size;
	font-family: $font-family;
	// background: $background-color;
	color: $font-color;
	margin: -1px;
	line-height: 1.4;	
    overflow-x: hidden;

}

input[type="text"],
input[type="date"],
input[type="number"],
input[type="password"],
input[type="tel"],
select,
select > option,
textarea {
	font-family: $font-family;
	font-size: 0.875rem;
}

.AddressInfo {
	&__form-container {
		margin: 22px auto 84px;
		max-width: 100%;
	}

	&__description {
		text-align: center;
		font-family: "GreycliffCF-Light", sans-serif;
		font-size: 0.875rem;

		p {
			margin: 0;
		}
	}

	&__radio-group {
		width: 100%;
		padding-bottom: 6px;
	}
	
	&__row{
		padding-top: 10px;
		  
	}

	&__buttons {
		margin-top: 76px;
		display: flex;
		justify-content: flex-end;
	}
}

@import "../../../../assets/styles/responsive";

.OTPValidate {
  &__heading {
    margin-bottom: 0;
    font-family: "GreycliffCF-DemiBold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    padding-bottom: 50px;
    color: #253342;
  }

  &__subHeading {
    font-family: "GreycliffCF-Light";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    padding-bottom: 40px;
    color: #5f6b76;
  }

  &__radio-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__radio {
    margin-bottom: 10px;
    vertical-align: middle;
  }

  &__form-input-container {
    text-align: center;
    display: flex;

    @include screen-size("small") {
      width: 350px;
    }
  }

  &__form-input {
    max-width: 800px;
  }

  &__card {
    background: #ffffff;
    border: 0.5px solid #c4c4c4;
  }
  &__text {
    
    font-family: "GreycliffCF-DemiBold" ;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #253342;
  }
  
  &__input {
   
    min-width: 56px;
    min-height: 56px;
  }

  &__form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 10px;
  }
}

.button {
  cursor: pointer;
  border: 1px solid $color-yellow;
  border-radius: 24px;
  background-color: $color-yellow;
  padding: 15px;
  font-family: "GreycliffCF-DemiBold", sans-serif;
  font-size: 16px;
  min-width: 125px;
  color: #000000;

  transition: background-color 0.25s ease, border-color 0.25s ease;

  @include screen-size("small") {
    padding: 15px;
    min-width: 262px;
  }

  &:hover {
    // color: $color-yellow;
    background-color: $color-dark-yellow;
    // border-color: $color-navy;
  }

  &--clear {
    background-color: transparent;

    &:hover {
      color: #000000;
      border-color: $color-dark-yellow;
      background-color: transparent;
    }
  }

  &--white {
    background-color: #ffffff;

    &:hover {
      color: #000000;
      background-color: #eaeaea;
      border-color: $color-dark-yellow;
    }
  }

  &:disabled,
  &[disabled] {
    background-color: #cccccc;
    color: #666666;
    border-color: #cccccc;
    cursor: not-allowed;
    pointer-events: all !important;
  }
}

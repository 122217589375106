@import "../../../../assets/styles/responsive";

.IdInfo {
	&__text {
		text-align: center;
		max-width: 600px;
		margin: 48px auto 0;
		font-size: 14px;
		font-family: "GreycliffCF-Light", sans-serif;
	}

	&__accept {
		border: 1px solid #808080;
		border-radius: 5px;
		padding: 0 14px;
		font-size: 14px;
		font-family: "GreycliffCF-Light", sans-serif;
	}

	&__upload {
		display: flex;
		flex-direction: column;
		width: 100%;
		table-layout: fixed;
		margin: 50px auto 70px;
	}

	&__upload-row {
		display: flex;
		flex-direction: column;

		@include screen-size("small") {
			flex-direction: row;
		}
	}

	&__button {
		margin: 5px;
		width: 100%;
	}
	&__upload-label {
		padding: 30px 0 10px;
		// text-align: right;
		font-size: 14px;
		font-family: "GreycliffCF-DemiBold", sans-serif;

		@include screen-size("small") {
			padding: 10px 26px 10px;
			align-items: center;
			justify-content: flex-end;
			display: flex;
			width: 40%;
			min-width: 40%;
		}
	}

	&__label-and-tooltip {
		display: inline-block;
		position: relative;
		padding-right: 35px;

		.InputInfoTooltip {
			right: 0;
      top: unset;
      width: 22px;
      height: 22px;

      @include screen-size("small") {
        top: -2px;
      }

      &__icon{
        width: 22px;
        height: 22px;
      }
		}

    
	}

	&__terms-main-title {
		font-size: 22px;
		font-family: "GreycliffCF-DemiBold", sans-serif;
		color: #555555;
		margin-bottom: 0;
	}

	&__terms {
		margin-top: 0;
	}

	&__terms-title {
		color: #555555;
		font-size: 14px;
		font-family: "GreycliffCF-DemiBold", sans-serif;
		margin-top: 35px;
		margin-bottom: 5px;
	}
	&__terms-text {
		background-color: #f0f0f0;
		border: 1px solid #808080;
		padding: 12px;
		height: 250px;
		overflow-y: auto;
		font-size: 12px;
		font-family: "GreycliffCF-Regular", sans-serif;

		p {
			margin-top: 0;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;
		margin-bottom: 84px;
		padding-right: 15px;
		padding-left: 15px;
	}
}

.grid {
  &__row {
    display: flex;
    // flex-direction: column;
    flex: 1;
    // margin: 0 -16px;

    @include screen-size("large") {
      flex-direction: row;
    }

    @include screen-size("mobil") {
      flex-direction: column;
    }
    @include screen-size("small") {
      flex-direction: row;
    }
  }

  &__rowFDirC {
    display: flex;
    // flex-direction: column;
    flex: 1;
    // margin: 0 -16px;
    flex-direction: row;
    grid-template-columns: repeat(6, 1fr);
    max-height: 105px;
  }

  &__rowProcess {
    justify-content: center;
    display: flex;
    background: #e5e5e5;
  }

  &__column {
    flex: 1;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
    @include screen-size("mobil") {
      margin-bottom: 16px;
    }
  }

  &__columnWitoutPadding {
    margin-bottom: 0px;
  }

  &__rowU {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0 -16px;

    @include screen-size("small") {
      flex-direction: row;
    }
  }

  &__rowU3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @include screen-size("large") {
      grid-template-columns: repeat(3, 1fr);
    }

    @include screen-size("mobil") {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__columnU {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__columnU2 {
    grid-column: 1 / 8;
    padding-left: 16px;
    padding-right: 16px;
    background: #ffffff;
    border: 0.4px solid #c4c4c4;
  }

  &__columnU4 {
    grid-column: 2 / 7;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__columnU3 {
    grid-column: 1 / 3;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__columnU6 {
    grid-column: 8 / 8;
    padding-left: 16px;
    padding-right: 16px;
  }

  &__alignEnd {
    display: flex;
    align-items: center;
    align-self: flex-end;

    @include screen-size("mobil") {
      display: none;
    }
  }

  &__alignStart {
    display: flex;
    align-items: center;
    align-self: flex-start;

    @include screen-size("mobil") {
      display: none;
    }
  }
}

@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/responsive";

.PackageInfo {
	&__unpaid-text {
		margin: 42px 0 56px;
		text-align: center;
		font-family: "GreycliffCF-Light";
	}
	&__packages {
		// max-width: 740px;
		// margin: 44px auto 84px;
		margin: 0 auto;
	}

	&__buttons {
		margin-top: 10px;
		padding-left: 20px;
		display: flex;
		justify-content: flex-end;
		margin-right: 15px;
	}

	&__smaller-container {
		max-width: 740px;
		margin: 0 auto;
	}

	&__card {
		background: #ffffff;
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
		border-radius: 10px;
		width: 315px;

		border-left: none;
		border-right: none;

		&:hover {
			-webkit-box-shadow: 0px 0px 18px 0px rgb(0 0 0);
			-moz-box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.5);
			box-shadow: 0px 0px 18px 0px rgb(0 0 0);
		}
		// @include screen-size("mobil") {
		//   padding-bottom: 20px;
		// }
	}

	&__cardHeader {
		display: flex;
		align-items: center;
		text-align: center;
		background: #2a51a3;
		border-radius: 50px;
		letter-spacing: 0.02em;
		display: block;
		color: #ffffff;
		font-family: "GreycliffCF-Bold";
		// padding-top: 25px;
		min-height: 80px;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 75px;

		border: solid 5px #2a51a3;
		border-color: transparent transparent #2a51a3 transparent;
		border-top-left-radius: 0em 0em;
		border-top-right-radius: 0em 0em;
		border-bottom-right-radius: 15em 2em;
		border-bottom-left-radius: 15em 2em;
	}
	&__cardText {
		padding: 15px 10px;
		font-family: "GreycliffCF-light";
		font-style: normal;
		font-weight: 700;
		font-size: 22px;
		line-height: 26px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;

		min-height: 225px;
		color: #253342;

		flex-direction: column;
	}

	&__cardMain {
		min-height: 10px;
		padding-top: 100px;
	}

	&__cardFooter {
		background: #ffffff;
		border: 0.5px solid #c4c4c4;
		border-left: none;
		border-right: none;
	}
	&__button {
		min-width: 125px;
		cursor: pointer;
		border: 1px solid #ffc900;
		border-radius: 24px;
		background-color: #ffc900;
		padding: 8px;
		font-family: "GreycliffCF-DemiBold";
		font-size: 16px;
		color: #000000;
		transition: background-color 0.25s ease, border-color 0.25s ease;
		max-height: 45px;
	}
}

.PayloadSelector {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: background-color 0.3s linear;
	margin-top: 36px;

	&__button {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		background-color: #ffffff;
		border: 1px solid $color-light-grey;

		font-family: "GreycliffCF-Light";
		font-size: 14px;
		margin: 0;
		padding: 10px 30px;
		width: 125px;

		outline: none;

		@include screen-size("small") {
			flex-direction: row;
		}

		&:hover,
		&:focus {
			background-color: $color-yellow;
		}

		&.active {
			border-color: #0080f7;
			background-color: #f3f3f3;
		}
	}

	&__icon {
		width: 80px !important;
		height: 80px !important;
	}

	&__text {
		color: $color-grey;

		@include screen-size("small") {
			margin-left: 20px;
		}
	}
}

.Package {
	color: #4d4d4d;

	&__cell {
		// padding-top: 20px;
		padding-bottom: 20px;
		padding-right: 10px;
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 13px;
		align-items: center;
		letter-spacing: 0.02em;

		color: #253342;
		// border-bottom: 1px solid $color-light-grey;
	}

	&__title {
		font-size: 21px;
		font-family: "GreycliffCF-Bold";
	}

	&__title--navy {
		color: $color-navy;
	}

	&__title__description {
		font-size: 14px;
		font-family: "GreycliffCF-Light";
		color: $color-grey;
		margin-top: 10px;
		margin-bottom: 30px;
	}

	.RadioButton__select-cell {
		padding-right: 40px;
	}
}

.Benefits {
	&__table-row {
		display: table-row;
	}

	&__table-heading {
		display: table-cell;
		color: #4d4d4d;
		font-family: "GreycliffCF-Bold";
		font-size: 14px;
		border-bottom: 1px solid $color-light-grey;
		padding-bottom: 5px;
		padding-right: 10px;
		white-space: nowrap;

		&--no-border-bottom {
			border: none;
		}
	}

	&__table-heading__icon {
		width: 11px;
		height: 11px;
		margin-right: 6px;
	}
	&__table-heading__caret {
		width: 11px;
		height: 11px;
		margin-left: 6px;
		color: $color-light-grey;
	}
}

.Benefit {
	padding-bottom: 10px;

	&__value-group {
		white-space: nowrap;
		display: flex;

		&--wrap-text {
			white-space: normal;
		}
	}

	&__value {
		font-family: "GreycliffCF-Bold";
		font-style: normal;
		font-weight: 700;
		font-size: 22px;
		line-height: 26px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;

		color: #253342;
	}

	&__unit {
		font-family: "GreycliffCF-demibold";
		font-style: normal;
		font-weight: 700;
		font-size: 22px;
		line-height: 26px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;

		color: #253342;
	}

	&__description {
		font-family: "GreycliffCF-demibold";
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 19px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;

		color: #919fac;
	}
}

.Price {
	padding-bottom: 10px;

	&__group {
		white-space: nowrap;
		display: flex;

		&--wrap-text {
			white-space: normal;
		}
	}

	&__unit-per-period {
		font-family: "GreycliffCF-Bold";
		font-style: normal;
		font-weight: 700;
		font-size: 10px;
		line-height: 12px;
		display: flex;
		align-items: center;

		color: #5f6b76;
	}

	&__value {
		font-family: "GreycliffCF-Bold";
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 24px;
		display: flex;
		align-items: center;
		letter-spacing: 0.02em;

		padding-right: 7px;
		color: #5f6b76;
	}

	&__description {
		font-family: "GreycliffCF-Bold";
		font-size: 10px;
		color: #8a8a8a;
	}
}

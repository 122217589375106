@import "../../../assets/styles/variables";
@import "../../../assets/styles/responsive";

.Container {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1100px;
  width: 100%;
  box-sizing: border-box;

  &--align-contents-middle {
    margin-top: auto;
    margin-bottom: auto;
  }

  &--align-contents-max-width-27 {
    max-width: 37%;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
    min-width: 400px;
  }

  @include screen-size("small") {
    // max-width: $page-width;
    min-width: 400px;
  }

  &--narrow {
    @include screen-size("small") {
      max-width: $page-width-narrow;
    }
  }
}

// !IMPORTANT: ANY CHANGE MADE HERE REQUIRES CHANGE IN variables.module.scss TO KEEP CONSISTENCY

$font-size: 0.875rem;
$font-family: "GreycliffCF-Bold";

$page-width: 1172px;
$page-width-narrow: 1100px;
$page-horizontal-padding: 60px;

$color-blue: #2a51a3;
$color-navy: #29367a;
$color-dark: #333333;
$color-grey: #979797;
$color-light-grey: #c4c4c4;
$color-yellow: #ffc900;
$color-dark-yellow: #e6b809;
$color-green: #27ae60;
$color-red: #eb5857;

$color-shadow: rgba(51, 51, 51, 1);
$color-overlay: rgba(255, 255, 255, 0.8);

$breakpoints: (
	mobil: 750px,
	small: 992px,
	large: 1200px,
);

$media: (
	mobil: (
		max-width: #{map-get($breakpoints, mobil)},
	),
	small: (
		min-width: #{map-get($breakpoints, mobil)},
		max-width: #{map-get($breakpoints, large)},
	),
	large: (
		min-width: #{map-get($breakpoints, large)},
	),
);

$background-color: $color-blue;
$heading-color: $color-navy;
$subheading-color: $color-dark;
$font-color: $color-grey;

.text {
	&--dark {
		color: #000000;
	}

	&--center {
		text-align: center;
	}

  &--color-808080 {
    color: #808080;
  }

  &--mt-40 {
    margin-top: 40px;
  }
  &--mt-100 {
    margin-top: 100px;
  }

  &--pt-100 {
    padding-top: 100px;
  }
}
